import React, { useState } from 'react';
import Navbar from '../components/Navbar';

const Gallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <>
        <Navbar/>
        <div className='flex flex-col w-full'>
            <div class='bg-gradient-to-br from-[#D4687E] to-[#7030A0] py-8 items-center justify-center flex flex-col'>
                <h1 className='text-3xl font-medium text-center text-white'>
                    Gallery
                </h1>
                <p className='text-xl font-medium text-center text-white'>
                    "Canine Excellence Captured"
                </p>
            </div>
            <div className='w-full flex flex-wrap mt-4 space-x-2 ml-2'>
                {[...Array(20).keys()].map((index) => (
                    <div
                        key={index}
                        className='w-full md:w-[200px] h-[300px] overflow-hidden cursor-pointer'
                        onClick={() => openModal(`/../assets/images/gallery/${index + 1}.png`)}
                    >
                        <img
                            src={`/../assets/images/gallery/${index + 1}.png`}
                            className='w-full h-full object-cover'
                            alt={`Gallery Image ${index + 1}`}
                        />
                    </div>
                ))}
            </div>

            {selectedImage && (
                <div className="fixed  w-full h-full flex items-center justify-center bg-black bg-opacity-75">
                    <button onClick={closeModal} className='absolute top-4 right-4 text-white text-xl'>
                        &times;
                    </button>
                    <img
                        src={selectedImage}
                        className='w-full h-full object-contain max-w-[80%] max-h-[80%]'
                        alt='Selected Image'
                    />
                </div>
            )}
        </div>
        </>
    );
};

export default Gallery;
