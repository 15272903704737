import React, { useState } from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

const Navbar = () => {
  let links = [
    { name: "Home", link: "/#home" },
    { name: "About Us", link: "/#about" },
    { name: "Blog", link: "/#blog" },
    { name: "Pricing", link: "/#pricing" },
    { name: "Review", link: "/#review" },
    { name: "Contact Us", link: "/#contact" },
  ];
  let [open, setOpen] = useState(false);

  return (
    <>
      <nav className='shadow-md w-full sticky top-0 left-0 font-[Montserrat] font-medium text-medium z-[1]'>
        <div className='md:flex items-center justify-around bg-white py-3.5 md:px-10 px-7'>
          <Link to="/" className='flex items-center '>
            <span>
              <img
                className="w-[100px] h-[80px] object-cover"
                src="../assets/images/logo.png"
                alt=""
              />
            </span>
          </Link>
          <div
            onClick={() => setOpen(!open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          >
            <ion-icon name={open ? "close" : "menu"}></ion-icon>
          </div>
          <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-19.5 ' : 'top-[-490px]'}`}>
            {
              links.map((link) => (
                <li key={link.name} className='md:ml-8 md:my-0 my-7'>
                  {/* <NavLink
                    to={link.link} 
                    activeClassName='text-blue-600'
                    className='text-black hover:text-[#7030A0] duration-500'
                  >
                    {link.name}
                  </NavLink> */}

                  <NavHashLink
                    to={link.link}
                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}
                    activeClassName='text-blue-600'
                    className='text-black hover:text-[#7030A0] duration-500'

                  >
                    {link.name}
                    
                  </NavHashLink>

                </li>
              ))
            }
            <li className='md:ml-8 md:my-0 my-7 md:hidden'>
              <Link to='/gallery' className='text-black hover:text-[#7030A0] duration-500'>Gallery</Link>
            </li>
          </ul>
          <Link to="/gallery" className='p-3 hidden md:block bg-[#7030A0] font-medium rounded-[10px] text-white hover:font-bold'>
            Gallery
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
