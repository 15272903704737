import './App.css';
import {  Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Navbar from './components/Navbar';
import Homepage from './pages/Homepage';
import Gallery from './pages/Gallery';

function App() {
  return (
   <Router>
    <Routes>
    <Route path='/' element={<Homepage/>}/>
    <Route path='/gallery' element={<Gallery/>}/>



    </Routes>
   </Router>
  );
}

export default App;
