import React, { useState } from 'react';
import Navbar from '../components/Navbar';


const reviewsData = [
  {
    id: 1,
    content: "Tammy is a fantastic dog trainer to work with! She assess your dog and their current routine and behaviors and customizes her approach based on your concerns and your dog’s needs. She is extremely knowledgable and sets you up for success by letting you practice training exercises with your dog after she models it. She values your time and fits as much as she can effectively into a session. We love that she comes to your home and can see your dog in their natural environment. She has been helping our dogs with basic obedience and leash manners and we can already see the progress since getting her expert input. You have to be willing to follow through with the exercises and training in order to see the results you want, but if you are willing to do that, you will be successful. We highly recommend Tammy!",
    author: "Leanna Jividen",
    avatar: "/../assets/images/img1.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 2,
    content: "Tammy has invaluable background knowledge and is always readily available to help. She is calm and patient, and really listens. Tammy even had my kids practice some of the skills learned. She really goes above and beyond what her job description entails, I would recommend her 1000%",
    author: "Courtney Cox",
    avatar: "/../assets/images/img2.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 3,
    content: "Tammy is an incredible trainer.  Kind, professional and patient; she has given us great support and instruction.  Highly recommend!",
    author: "Jose OConnell",
    avatar: "/../assets/images/img3.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 4,
    content: "I would absolutely recommend Tammy. All of her advice and suggestions were extremely helpful. She is extremely knowledgeable and professional. My puppies loved her!!",
    author: "Tina Cymbrowski",
    avatar: "/../assets/images/img4.jpg",
    rating: "/../assets/images/5star.png",
  },

  {
    id: 5,
    content: "she's excellent!! Completely helped when our dog was 'new' to the family... from training to treat and food education 👍👍😊",
    author: "Rae Van Wey",
    avatar: "/../assets/images/img5.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 6,
    content: "I have known Tammy for many many years and know of the extreme love and patience she has for dogs. I am that person who will tell you that I do not trust anyone with my dog, I’m probably a little too extreme, but I would not hesitate to leave her in Tammy’s care for training, even out of my eye-site. For anyone who knows me knows that is a big deal. I would highly recommended Tammy for your training needs, you will not be disappointed. Your dog will be in good and loving hands.",
    author: "Debbie Conti",
    avatar: "/../assets/images/img6.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 7,
    content: "Tammy was great working with our obnoxious little Cock-A-Poo Oscar.  She was loving, patient and kind, and really educated us on how to handle him 😊. I would recommend her to anyone needing help with steering your dog’s personality and attitude to fit in with your family. ❤️ Thank you Tammy!!",
    author: "Sue Bernas Mall",
    avatar: "/../assets/images/img7.jpg",
    rating: "/../assets/images/5star.png",
  },
  // {
  //   id: 8,
  //   content: "Miss Tammy Conti was an answer to prayer!! Her advice wisdom and encouragement was exactly what our family needed. We were first time dog owners with very little training experience. We were confused, frustrated and unsure whether or not the breed of dog we had chosen was the best fit for our family. It was heartbreaking for us to feel this way. Within only a few short days of having him home he had really become a part of us. Tammy stepped right in without hesitation spent time with us and got to know Mack and his mannerisms. She reassured us that his behavior was not out of the ordinary. She taught us the tricks and trades that were necessary to give him the proper training he needed. Within one day we already saw changes in Mack and within a few short months he had grown up so much. She taught and equipped us with the knowledge we needed to raise Mack. She is patient kind loving and always willing to help in any way she can! Her love for animals and especially dogs is evident to all!! We would recommend Tammy to anyone that needs the best in dog care. She's amazing and we couldn't have thought of anyone else to help us with Mack. ~The Paufler Family",
  //   author: "Kerri Lynn Bernas Paufler",
  //   avatar: "/../assets/images/lady.png",
  //   rating: "/../assets/images/5star.png",
  // },
  {
    id: 8,
    content: "Tammy is absolutely incredible! She has specialized training with my girl Shay and taught me how to be a better owner as well. In addition to customizing our training Tammy always makes sure to tackle anything I personally want to address for me and my pup. I have 100000% confidence in Tammy and her abilities to help anyone and their dogs.",
    author: "Kaylee Robinson",
    avatar: "/../assets/images/img8.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 9,
    content: `Let me start by saying I call Tammy the dog whisperer😊 My dog training with Tammy started when our adult dog got seperation anxiety out of the blue. Tammy came to our house to assess the situation and Daisy immediately bonded with Tammy. Tammy "trained" us on how we can help Daisy to become a confident, secure dog even when separated from us. Fast forward about 10 years, we got a puppy! Tammy trained us from teaching Sophie's  name, to basic commands and manners (teaching behaviors we don't and do want). She is amazing at assessing each individual dogs personality and training accordingly. Tammy is so patient kind and knowledgeable with both dogs and their humans! I highly recommend Tammy for your dog training needs.`,
    author: "Michele Dailey",
    avatar: "/../assets/images/img9.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 10,
    content: `When we got our Rosie about two years ago, we knew we wanted to get some training in to work with our three young kids. Tammy came in with so much knowledge to share and we learned an extensive amount in the short time she spent with us! She was so patient, kind, and informative. We get compliments constantly on how well behaved Rosie is and I know that has so much to do with Tammy’s guidance! Thank you, Tammy!`,
    author: "Christie Delaney",
    avatar: "/../assets/images/img10.jpg",
    rating: "/../assets/images/5star.png",
  },
  {
    id: 11,
    content: `I had a dog growing up, but I recently got my first dog that I was in charge of training. Romeo was a very skiddish puppy when we first got t 90thhim
    and he wouldn't even come near us. Tammy was over the next day and by the end of her first session with us, he was comfortable coming up to me and my boyfriend, letting us pet him. Any question we have had since, she has been available to answer. We are grateful for Tammy's time and patience with our puppy and are amazed at how far he has already come!`,
    author: "Lauren Harvey",
    avatar: "/../assets/images/img11.jpg",
    rating: "/../assets/images/5star.png",
  },
  // {
  //   id: 11,
  //   content: ``,
  //   author: "Michele Dailey",
  //   avatar: "/../assets/images/img9.jpg",
  //   rating: "/../assets/images/5star.png",
  // },
];
const Homepage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const getReviewIndex = (index) => {
    const totalReviews = reviewsData.length;
    return (index + totalReviews) % totalReviews;
  };

  const slideNext = () => {
    const nextIndex = currentIndex + 1;
    const totalReviews = reviewsData.length;
    if (nextIndex < totalReviews) {
      setCurrentIndex(nextIndex);
    } else {
      // setCurrentIndex(0); 
    }
  };

  const slidePrev = () => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
    } else {
      // setCurrentIndex(reviewsData.length - 1); 
    }
  };

  const sliderStyle = {
    transform: `translateX(-${currentIndex * (100 / 3)}%)`,
    transition: 'transform 0.5s ease-in-out'
  };


  return (
    <>
      <Navbar />

      <div className='flex flex-col w-full h-min-screen font-[kaisei-decol-regular]'>
        {/* first division from here */}
        <div className="flex homepage flex-col xl:flex-row justify-center md:items-start bg-[#FCF1F6] w-full" id='home'>
          <div className="flex homepagefirst flex-col w-full lg:w-1/2 justify-center lg:items-start text-center lg:text-left md:mt-20 ">
            <img className='absolute top-[150px] left-[30px]' src="/../assets/images/foot.png" alt="" />
            <img className='absolute top-[500px] left-[30px] md:hidden' src="/../assets/images/foot.png" alt="" />
            <img className='hidden md:inline-block absolute top-[800px] left-[30px]' src="/../assets/images/foot.png" alt="" />
            <h1 className='text-[64px] heading md:text-[120px]  md:text-right w-full md:w-[90%] lg:ml-auto' id='home-heading'>
              Dog Training With
              <span className='home-content'>
                <p className=' tammy justify-center md:inline-block text-purple-800 md:text-[120px] 2xl:text-[150px] text-[64px] font-bold  ' id='home-content'>Tam</p>
              </span>
            </h1>
            <p className="text-[24px] mt-4  font-[kaisei-decol-regular] text-[#DD6179] w-full md:w-[80%] lg:ml-auto" id='home-para'>
              Empower your dog with Tammy's transformative training. Build a foundation of trust and obedience, fostering a lifelong partnership filled with joy and companionship.
            </p>
            <p className="font-bold w-full md:w-[80%] lg:ml-auto text-lg mt-4" id='home-para1'>
              Book Your Free Consultation With Tammy
            </p>
            <div className="flex md:flex-row flex-col justify-center md:ml-auto md:pr-12 items-center gap-4 mt-8" id='home-para2'>
              <button className="flex text-sm items-center gap-2 border-2 border-[#7030A0] text-[#7030A0] py-[15px] px-6 md:px-8 hover:bg-[#7030A0] hover:text-white rounded-lg transition-colors">
                <i className="fab fa-facebook-f"></i>
                Message Us on Facebook
              </button>
              <button className="flex text-sm items-center gap-2 bg-[#7030A0] text-white py-[15px] px-8 hover:bg-white hover:text-[#7030A0] rounded-lg transition-colors">
                <i className="fas fa-phone"></i>
                Call Us at 716-381-0976
              </button>
              <img className='w-20 h-24  mt-object-cover' src='/../assets/images/star.png' alt='Five Star Rated' />
            </div>
          </div>
          <div className="homepagesecond md:w-1/2">
            <img className="  object-cover w-full" src="/../assets/images/lady.png" alt="Tammy with a dog" />
          </div>
        </div>







        {/* first division up to here */}

        {/* second division from here */}
        <div className="bg-[#7030A0] w-full flex flex-col items-center justify-center px-6 py-12" id='about'>
          <h1 className="text-white text-3xl md:text-4xl font-semibold border-white border-b-[7px]">
            About Us
          </h1>

          <div className="w-full max-w-6xl mx-auto p-4">

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="flex justify-center">
                <img
                  src="/../assets/images/dog.png"
                  alt="Consultation"
                  className="w-full max-w-md h-auto"
                />
              </div>
              <div>
                <p className='text-white text-lg leading-relaxed'>
                  Welcome to my Dog Training Page. I want to introduce myself and give you a little insight as to who I am and how I became a dog trainer who loves what I do. My name is Tammy and I have been a dog trainer in the WNY area for the past 25 years among other careers that I love (which all involve teaching at its core). It began long before my first official dog training job. I was fascinated at how dogs communicated with each other, other species, and how humans and dogs interacted. My intrigue with dogs never wavered. As I grew into a teen and young adult, I would find myself reading and learning everything I could about canines and implementing the techniques on my own dogs as well as clients. As the years have progressed, I’ve studied many forms of training techniques and discovered the many benefits of various tools and strategies that work. I’ve recently recognized that there is a great need for personalized dog training.


                </p>
              </div>
              <div>
                <p className='text-white text-lg leading-relaxed'>
                  My job is to be your dog’s advocate and your teacher to gain understanding of how your dog communicates with you. The way I try to explain what I do and why it seems to work so well is that although there is a so-called black and white methodology that applies to canines in general, like humans, dogs are individuals. Like people, our day-to-day life and outside influences have much to do with our own behavior, so why would it be any different for our four-legged family member? I believe that putting in the work and time to get to the root of who your dog is and their personal needs, in their own home, works best for the dog and their humans for the long haul. For example, I work to understand why your dog may be showing an undesired behavior based on various factors that may be at play and don’t just want to put a band-aid on a wound that will continue to come back time and time again. I also specialize in setting your new puppy (the younglings) up for success so that there are no undesired behaviors created from the start. If this is something that you feel you and your four-legged family member may need or benefit from, don’t hesitate to reach out. I hope I get to help you and your canine companion in the future.


                </p>
              </div>
              <div className="flex justify-center">
                <img
                  src="/../assets/images/dog1.png"
                  alt="Consultation"
                  className="w-full max-w-md h-auto"
                />
              </div>
            </div>
          </div>
        </div>

        {/* second division upto here */}
        {/* third division */}
        <div class="flex w-full bg-[#FCF1F6]" id="blog">

          <div class="flex flex-wrap mx-auto w-full md:w-4/5 bg-[#FCF1F6] justify-center space-x-4 md:space-x-10 py-10">

            <div class="mx-auto relative">
              {/* <img class="absolute top-0 left-0 md:top-[2100px] md:left-[30px]" src="/../assets/images/foot.png" alt="" /> */}
              <img src="/../assets/images/kathmandu.png" alt="" class="w-full md:w-auto" />
            </div>

            <div class="flex flex-col items-left w-full md:w-3/5 lg:w-2/3 xl:w-1/2">
              <h1 class="text-xl md:text-4xl font-semibold mt-4 md:mt-0 w-[20%] md:w-[12%] md:border-b-7 md:border-[#7030A0] border-b-4 border-[#7030A0] pb-1">
                Blog
              </h1>

              <p class="font-semibold text-sm md:text-xl mt-2">
                The Street Dogs Of Kathmandu
              </p>

              <div class="w-24 h-24 md:w-48 md:h-48 border-4 border-dotted mt-2"></div>

              <p class="flex flex-wrap space-x-2 md:space-x-6 font-medium text-lg mt-2">
                2022-10-22
                Tammy Conti & Rob Vanwey
              </p>

              <p class="text-lg font-medium mt-2 md:mt-4">
                “Establishing a community treatment program for each ward of Kathmandu will
                swiftly reduce the number of occurrences of various diseases and improve the
                lives of both humans and dogs. In addition, such a program will humanely lower
                the overall street population in each ward, and Kathmandu on the whole. Once that
                is accomplished, focus can turn to other issues.”
              </p>

              <p class="text-lg font-bold mt-4">
                See my article on helping the street dogs of Nepal:
              </p>

              <div class="text-lg font-bold mt-4">
                <a href="https://robertvanwey.substackcom/p/the-street-dogs-of-kathmandu">
                  Robertvanwey.substackcom/p/the-street-dogs-of-kathmandu
                </a>
              </div>

            </div>
          </div>
        </div>



        {/* third division */}

        {/* Fourth division */}
        <div className='flex flex-wrap w-full bg-[#7030A0] py-16' id='pricing'>
          <div className='flex flex-wrap w-[90%] mx-auto items-center justify-between bg-[#7030A0]' >
            <div className='flex flex-col max-w-2xl space-y-6 mb-4 '>
              <h1 className='text-white  text-lg md:text-3xl font-bold border-white  w-[60%] md:w-[35%] md:border-b-[7px] border-b-[5px] '>
                Session Rates
              </h1>
              <p className='font-medium  text-sm md:text-lg text-white flex flex-row gap-2'>
                <img src="/../assets/images/vector.png" className='w-4 h-4 object-cover md:mt-[3px]' alt="" />
                The First Hour: $90
              </p>
              <p className='font-medium text-sm md:text-lg text-white flex flex-row gap-2'>
                <img src="/../assets/images/vector.png" className='w-4 h-4 object-cover mt-[3px]' alt="" />

                Each additional hour: $60, with pricing adjustments based on your dog's individual needs.
              </p>
              <p className='font-medium text-sm md:text-lg text-white flex flex-row gap-2'>
                <img src="/../assets/images/vector.png" className='w-4 h-4 object-cover mt-[3px]' alt="" />

                Sessions beyond 10 miles may have an extra charge.
              </p>
              <p className='font-medium text-sm md:text-lg text-white flex flex-row gap-2'>
                <img src="/../assets/images/vector.png" className='w-4 h-4 object-cover mt-[3px]' alt="" />

                Additional costs for multiple dog households vary depending on the specific needs of each dog.
              </p>


            </div>
            <div className='flex flex-wrap w-50% gap-x-10 justify-center md:ml-16 '>
              <img src="/../assets/images/two.png" className='object-cover w-[350px] h-[400px] rounded-lg' alt="" />
              <img src="/../assets/images/one.png" className='w-[350px] h-[500px] mt-16 rounded-lg' alt="" />

            </div>
          </div>
        </div>
        {/* fourth division */}

        {/* fifth division */}
        <div className="bg-[#FCF1F6] py-12  justify-center items-center space-y-10" id='review'>
          <h1 className='text-center  text-medium md:text-3xl font-semibold border-b-[6px] border-[#7030A0]  w-[90%] md:w-[40%] mx-auto'>
            Reviews From Our Happy Customers
          </h1>
          <div className="relative w-[90%] mx-auto  overflow-hidden">
            <div className="flex gap-4" style={sliderStyle}>
              {reviewsData.map((review, index) => (
                <div key={review.id} className="md:w-[500px] w-[95%] flex-shrink-0 bg-white shadow-md rounded-lg p-4 text-left flex flex-col justify-start items-start gap-4">
                  <p className="text-md">{review.content}</p>
                  <div className='flex items-center space-x-4'>
                    <img src={review.avatar} className='w-12 h-12 object-cover rounded-full' alt="Avatar" />
                    <div className='flex flex-col'>
                      <p className='text-sm font-semibold'>{review.author}</p>
                      <img src={review.rating} className='w-24' alt="Rating" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='flex items-center justify-center mt-6 space-x-4'>
              <button onClick={slidePrev} className='bg-[#7030A0] rounded-lg h-10 w-10 flex justify-center items-center'>
                <i className="fas fa-arrow-left text-white"></i>
              </button>
              <button onClick={slideNext} className='bg-[#7030A0] rounded-lg h-10 w-10 flex justify-center items-center'>
                <i className="fas fa-arrow-right text-white"></i>
              </button>
            </div>

          </div>
        </div>

        {/* fifth division */}

        {/* sixth division */}
        <div className="flex flex-col md:flex-row items-center bg-[#7030A0]" id='contact'>

          <div className="flex flex-col w-full md:w-[50%] p-4 space-y-6 md:space-y-12 md:ml-12">
            <h1 className='text-xl md:text-4xl text-white font-bold mt-4 md:mt-10 border-b-[6px] border-white  w-[90%] md:w-[50%]'>
              Contact Information
            </h1>
            <p className="font-bold text-white">
              For the fastest contact,  Call Us or Message Us
            </p>
            <div className="flex flex-wrap justify-center lg:justify-start md:items-center gap-4 mb-4">
              <button className="border border-solid border-white flex items-center gap-2 border-4 border-white font-medium text-white py-2 px-6 hover:bg-white hover:text-[#7030A0] rounded-lg transition-colors">
                <i className="fab fa-facebook-f"></i>
                Message Us on Facebook
              </button>
              <button className="flex items-center gap-2 bg-white text-[#7030A0] border-4 border-white font-medium py-2 px-6 hover:bg-[#7030A0] hover:text-white rounded-lg transition-colors">
                <i className="fas fa-phone"></i>
                Call Us at 716-381-0976
              </button>
            </div>

            <div className='flex flex-col space-y-6 md:space-y-12 mt-6'>
              <p className='text-sm md:text-md md:text-left text-white flex flex-row gap-1'>
                <i className="far fa-envelope mr-2 text-xl"></i>
                <a href='mailto:tammysdogtraining716@gmail.com' className='mt-1 w-[80%] md:font-bold '>Email:tammysdogtraining716@gmail.com </a>
              </p>
              <p className='text-sm md:text-md md:text-left text-white flex flex-row gap-2'>
                <i className="fab fa-facebook mr-2 text-xl"></i>
                <a href='https://facebook.com/DogTrainingwithTammy' target='_blank' className='mt-1 md:font-bold'>Facebook: Dog Training With Tammy </a>
              </p>
              <p className='text-sm md:text-left text-white ml-6 flex flex-grow gap-1'>
                <img src="/../assets/images/vector.png" className='w-4 h-4 object-cover md:mt-[3px]' alt="" />

                Visit My Facebook Page
              </p>
              <p className='text-sm md:text-left text-white ml-6 flex flex-grow gap-2'>
                <img src="/../assets/images/vector.png" className='w-4 h-4 object-cover md:mt-[3px]' alt="" />

                Like and Follow
              </p>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <img className="w-full h-auto object-cover" src="/../assets/images/lady.png" alt="Tammy with a dog" />
          </div>

        </div>

        {/* sixth division */}

        <div className=' border-t border-white flex bg-[#7030A0] justify-center items-center w-full py-6'>
          <h1 className='text-center text-white font-md text-xl'>
            Powered By Digi Technology America
          </h1>
        </div>




      </div>
    </>
  )
}

export default Homepage
